import React from 'react';

import { useBreakpoint } from '@hooks/useBreakpoint';

import {
  Container,
  Image,
  TextImageContainer,
  ImageTextContainer,
  ImageImageContainer,
  TextTextContainer,
  LeftColumnText,
  RightColumnText,
  StackContainer,
} from './twoColumnBlock.styles';

export interface TwoColumnBlockProps {
  leftColumn: {
    contentType: string;
    text: string;
    image: {
      alt: string;
      title: string;
      urls: [
        {
          size: string;
          url: string;
        }
      ];
    };
    fontSize: {
      size: string;
      spacing: string;
    };
    horizontalAlignment: 'left' | 'right' | 'center';
    verticalAlignment: string;
  };
  rightColumn: {
    contentType: string;
    text: string;
    image: {
      alt: string;
      title: string;
      urls: [
        {
          size: string;
          url: string;
        }
      ];
    };
    fontSize: {
      size: string;
      spacing: string;
    };
    horizontalAlignment: 'left' | 'right' | 'center';
    verticalAlignment: string;
  };
  desktopWidth: string;
  stackOnMobile: string;
}

export const TwoColumnBlock = ({ leftColumn, rightColumn, desktopWidth, stackOnMobile }: TwoColumnBlockProps) => {
  const { isMobile } = useBreakpoint();
  const desktopWidths = desktopWidth.split('-');
  const renderContent = () => {
    // Text-Image Condition
    if (leftColumn.contentType === 'text' && rightColumn.contentType === 'image') {
      return (
        <TextImageContainer>
          <LeftColumnText
            width={desktopWidths[0] + '%'}
            fontSize={leftColumn.fontSize.size}
            textAlign={leftColumn.horizontalAlignment}
            verticalAlign={leftColumn.verticalAlignment}
            dangerouslySetInnerHTML={{ __html: leftColumn.text }}
          />
          <Image desktopWidth={desktopWidths[1]} src={rightColumn.image.urls[0].url} alt={rightColumn.image.alt} />
        </TextImageContainer>
      );
    }

    // Image-Text Condition
    if (leftColumn.contentType === 'image' && rightColumn.contentType === 'text') {
      return (
        <ImageTextContainer>
          <Image
            desktopWidth={desktopWidths[0]}
            style={{ objectFit: 'contain' }}
            src={leftColumn.image.urls[0].url}
            alt={leftColumn.image.alt}
          />
          <RightColumnText
            width={desktopWidths[1] + '%'}
            fontSize={rightColumn.fontSize.size}
            textAlign={rightColumn.horizontalAlignment}
            verticalAlign={rightColumn.verticalAlignment}
            dangerouslySetInnerHTML={{ __html: rightColumn.text }}
          />
        </ImageTextContainer>
      );
    }

    // Image-Image Condition
    if (leftColumn.contentType === 'image' && rightColumn.contentType === 'image') {
      return (
        <ImageImageContainer>
          <Image
            desktopWidth={desktopWidths[0]}
            style={{ objectFit: 'contain' }}
            src={leftColumn.image.urls[0].url}
            alt={leftColumn.image.alt}
          />
          <Image
            desktopWidth={desktopWidths[1]}
            style={{ objectFit: 'contain' }}
            src={rightColumn.image.urls[0].url}
            alt={rightColumn.image.alt}
          />
        </ImageImageContainer>
      );
    }

    // Text-Text Condition
    if (leftColumn.contentType === 'text' && rightColumn.contentType === 'text') {
      return (
        <TextTextContainer>
          <LeftColumnText
            width={desktopWidths[0] + '%'}
            fontSize={leftColumn.fontSize.size}
            textAlign={leftColumn.horizontalAlignment}
            verticalAlign={leftColumn.verticalAlignment}
            dangerouslySetInnerHTML={{ __html: leftColumn.text }}
          />
          <RightColumnText
            width={desktopWidths[1] + '%'}
            fontSize={rightColumn.fontSize.size}
            textAlign={rightColumn.horizontalAlignment}
            verticalAlign={rightColumn.verticalAlignment}
            dangerouslySetInnerHTML={{ __html: rightColumn.text }}
          />
        </TextTextContainer>
      );
    }

    return null;
  };

  const renderStackContent = () => {
    // Text-Image Condition
    if (leftColumn.contentType === 'text' && rightColumn.contentType === 'image') {
      return (
        <StackContainer>
          <LeftColumnText
            width={'100%'}
            fontSize={leftColumn.fontSize.size}
            textAlign={'left'}
            verticalAlign={'flex-start'}
            dangerouslySetInnerHTML={{ __html: leftColumn.text }}
          />
          <Image
            style={{ width: '100%', objectFit: 'contain' }}
            src={rightColumn.image.urls[0].url}
            alt={rightColumn.image.alt}
          />
        </StackContainer>
      );
    }

    // Image-Text Condition
    if (leftColumn.contentType === 'image' && rightColumn.contentType === 'text') {
      return (
        <StackContainer>
          <RightColumnText
            width={'100%'}
            fontSize={rightColumn.fontSize.size}
            textAlign={'right'}
            verticalAlign={'flex-end'}
            dangerouslySetInnerHTML={{ __html: rightColumn.text }}
          />
          <Image
            style={{ width: '100%', objectFit: 'contain' }}
            src={leftColumn.image.urls[0].url}
            alt={leftColumn.image.alt}
          />
        </StackContainer>
      );
    }

    // Image-Image Condition
    if (leftColumn.contentType === 'image' && rightColumn.contentType === 'image') {
      return (
        <StackContainer>
          <Image
            style={{ width: '100%', objectFit: 'contain' }}
            src={leftColumn.image.urls[0].url}
            alt={leftColumn.image.alt}
          />
          <Image
            style={{ width: '100%', objectFit: 'contain' }}
            src={rightColumn.image.urls[0].url}
            alt={rightColumn.image.alt}
          />
        </StackContainer>
      );
    }

    // Text-Text Condition
    if (leftColumn.contentType === 'text' && rightColumn.contentType === 'text') {
      return (
        <StackContainer>
          <LeftColumnText
            width={'100%'}
            fontSize={leftColumn.fontSize.size}
            textAlign={'left'}
            verticalAlign={'flex-start'}
            dangerouslySetInnerHTML={{ __html: leftColumn.text }}
          />
          <RightColumnText
            width={'100%'}
            fontSize={rightColumn.fontSize.size}
            textAlign={'right'}
            verticalAlign={'flex-end'}
            dangerouslySetInnerHTML={{ __html: rightColumn.text }}
          />
        </StackContainer>
      );
    }

    return null;
  };

  return (
    <Container borderSpacing={leftColumn.fontSize?.spacing} margin={30}>
      {(!isMobile || (isMobile && !stackOnMobile)) && renderContent()}

      {isMobile && stackOnMobile && renderStackContent()}
    </Container>
  );
};
